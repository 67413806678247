@import "foundation/_settings.scss";
@import "carousel.scss";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../bower_components/foundation/scss/normalize.scss";
@import "../bower_components/foundation/scss/foundation/components/_accordion.scss";
@import "../bower_components/foundation/scss/foundation/components/_block-grid.scss";
@import "../bower_components/foundation/scss/foundation/components/_breadcrumbs.scss";
@import "../bower_components/foundation/scss/foundation/components/_buttons.scss";
@import "../bower_components/foundation/scss/foundation/components/_dropdown-buttons.scss";
@import "../bower_components/foundation/scss/foundation/components/_dropdown.scss";
@import "../bower_components/foundation/scss/foundation/components/_forms.scss";
@import "../bower_components/foundation/scss/foundation/components/_grid.scss";
@import "../bower_components/foundation/scss/foundation/components/_inline-lists.scss";
@import "../bower_components/foundation/scss/foundation/components/_labels.scss";
@import "../bower_components/foundation/scss/foundation/components/_offcanvas.scss";
@import "../bower_components/foundation/scss/foundation/components/_pagination.scss";
@import "../bower_components/foundation/scss/foundation/components/_reveal.scss";
@import "../bower_components/foundation/scss/foundation/components/_side-nav.scss";
@import "../bower_components/foundation/scss/foundation/components/_tables.scss";
@import "../bower_components/foundation/scss/foundation/components/_tabs.scss";
@import "../bower_components/foundation/scss/foundation/components/_thumbs.scss";
@import "../bower_components/foundation/scss/foundation/components/_top-bar.scss";
@import "../bower_components/foundation/scss/foundation/components/_type.scss";
@import "../bower_components/foundation/scss/foundation/components/_visibility.scss";
@import "../bower_components/owl.carousel/src/scss/owl.carousel.scss";
// endbower

@font-face {
  font-family: 'AgencyFB';
  src: url('../../dist/fonts/AGENCYR.eot');
  src: local('☺'), url('../../dist/fonts/AGENCYR.woff') format('woff'), url('../../dist/fonts/AGENCYR.ttf') format('truetype'), url('../../dist/fonts/AGENCYR.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AgencyFB-Bold';
  src: url('../../dist/fonts/AgencyFB-Bold.eot');
  src: local('☺'), url('../../dist/fonts/AgencyFB-Bold.woff') format('woff'), url('../../dist/fonts/AgencyFB-Bold.ttf') format('truetype'), url('../../dist/fonts/AgencyFB-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MyriadPro-Cond';
  src: url('../../dist/fonts/MyriadPro-Cond.eot');
  src: local('☺'), url('../../dist/fonts/MyriadPro-Cond.woff') format('woff'), url('../../dist/fonts/MyriadPro-Cond.ttf') format('truetype'), url('../../dist/fonts/MyriadPro-Cond.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('../../dist/fonts/MyriadPro-Regular.eot');
  src: local('☺'), url('../../dist/fonts/MyriadPro-Regular.woff') format('woff'), url('../../dist/fonts/MyriadPro-Regular.ttf') format('truetype'), url('../../dist/fonts/MyriadPro-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

p {
  font-size: 14px;
  color: #8e8e8e;
}

body {

  &.home {
    background-color: #000;
  }

}

.main-section {
  width: 100%;
  overflow: hidden;
}

.fixed-top-bar {
  height: 20px;
  background: #000;
}

p.align-left {
  text-align: left;
}
p.align-right {
  text-align: right;
}

nav {

  font-family: "AgencyFB";
  text-transform: uppercase;
  padding-bottom: 22px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      display: inline;

      &.current a {
        font-family: "AgencyFB-Bold";
        font-weight: bold;
      }
    }

    a {
      display: inline;
      padding: 0 30px 10px 30px;
      color: inherit;

      @media #{$small-only} {
        padding: 0 10px 10px 10px;
      }

      background-color: #fff;

      .home & {
        background-color: inherit;
      }

      font-size: 20px;
    }

  }
}

// Logo
#logo-container {

  &::after {
    content: " ";
    display: table;
  }

  clear: both;

  .lines {
    margin-top: 56px;
    border-bottom: 1px solid #000;
    
    .home & {
      border: 0;
    }
  }

  .logo {

    padding-bottom: 10px;

    img {
      display:block;
      margin:auto;

      margin: 32px auto 64px;

      .home & {
        margin: 62px auto 64px;
      }

      @media #{$small-only} {
        margin: 4px 0;
      }

    }
  }

  .subtext {

    .columns {
      padding: 0;
      margin: 0;
    }

    p {
      font-size: 32px;
      font-family: "AgencyFB";
      text-transform: uppercase;
      color: $white;
      line-height: 1.2;
      margin: 0;
      font-weight: bold;

      &.align-right,
      &.align-left {
        @media #{$small-only} {
          text-align: center;
        }
      }
    }
  }
}

.content {

  .clients & {
    margin-top: 170px;

    @media #{$small-only} {
      margin-top: 4px;
    }
  }

  margin-bottom: 120px;
  clear: both;

  p {
    font-family: "MyriadPro-Regular";
  }

  .work & p {
    font-family: Arial, Helvetica, Sans Serif;
    font-size: 11px;
    text-transform: uppercase;
  }

  .subtext {

    padding-top: 15px;

    p {

      text-align: center;
      color: #000;
      font-size: 13px;
    }

  }
}

// Content Category Page
.content-category {

  .work-listing {

    .item {

      img {
        height: 220px;
      }

      .panel {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(4, 6, 16, 0.43);
        padding: 100px 0;
        opacity: 0;
        transition: all .25s linear;
        text-align: center;

        &:hover {
          opacity: 1;
        }

        h2 {
          font-size: 20px;
          color: $white;
        }
      }
    }
  }
}

// Work item page
.work {


  .thumb-items {

    > .columns {
      padding: 0;

      &.right {
        padding-left: 2px;
      }

      > .columns {

        padding: 0;
        text-align: center;

        margin-bottom: 4px;

        img {
          vertical-align: middle;
          display: inline-block;
          max-height: 200px;

          @media #{$small-only} {
            max-height: none;
          }
        }
      }
    }
  }

}

// Backstretch indicators
.indicator-container {
  display: table;
  margin: 0 auto;

  bottom: 45px;
  right: 16px;

  text-align: center;
  position: absolute;

  @media #{$small-only} {
    position: relative;
    right: auto;
    bottom: 0;
  }

  ol.backstretch-indicators {

    min-width: 200px;
    margin: 0;

    @media #{$small-only} {
      right: auto;
      position: relative;
    }

    li {

      background-color: #786958;

      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 1px 10px;
      text-indent: -999px;
      cursor: pointer;

      border: 1px solid #786958;
      border-radius: 10px;

      &.active {
        background-color: #dbd6d2;
        border: 1px solid #dbd6d2;
      }
    }
  }

}

// Work share icons
.work-share {

  ul {
    list-style: none;
    margin: 0;

    li {

      background: url(../../dist/images/work-share.png) no-repeat;
      display: inline-block;
      cursor: pointer;

      &.fb{
        background-position: 0 -3px ;
        width: 17px;
        height: 15px;
      }

      &.pin{
        background-position: -22px 0;
        width: 24px;
        height: 19px;
      }

      &.tw{
        background-position: -52px -5px ;
        width: 16px;
        height: 13px;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
      }

    }
  }

}

// Social icons
.social-icons-container {
  display: table;
  margin: 0 auto;

  right: 42px;
  bottom: 60px;

  text-align: center;
  position: absolute;

  @media #{$small-only} {
    position: relative;
    right: auto;
    bottom: 0;
  }

  ul.social-icons {

    min-width: 100px;
    margin: 0;

    z-index: 100;

    list-style: none;

    @media #{$small-only} {
      right: auto;
      position: relative;
    }

    li {

      background: url("../../dist/images/social-icons-small.png") no-repeat;
      width: 16px;
      height: 16px;

      display: inline-block;
      cursor: pointer;

      a {
        display: block;
        width: 16px;
        height: 16px;
      }

      &.fb{
        background-position: 0 0;
        width: 16px;
        height: 16px;
      }

      &.tw{
        background-position: -18px 0;
        width: 16px;
        height: 16px;
      }

      &.pi{
        background-position: -36px 0;
        width: 16px;
        height: 16px;
      }

      &.wp{
        background-position: -56px 0;
        width: 16px;
        height: 16px;
      }

      &.feed{
        background-position: -76px 0;
        width: 16px;
        height: 16px;
      }

      &.inst{
        background-position: -94px 0;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.overlay-menu {
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

#contact-form {

  button {
    background-color: #ddd;
    color: #000;

    @media #{$small-only} {
      width: 100%;
    }
  }

  .error input {
    margin-bottom: 16px;
  }

}

// Contact page
.contact {

  .misc {
    text-align: center;
    text-transform: uppercase;
  }
}


.carousel-target {
  text-align: center;
  img {
    /*height: 332px;*/
  }
}
.carousel-item {
  cursor: pointer;

  img {
    /*height: 231px;*/
  }
}

.centered-content {
  text-align: center;
}

header {
  position: fixed;
  bottom: 20px;
  width: 100%;
  z-index: 2;

  background: $white;

  .home & {
    color: $white;
    background: none;
  }

  .header-lines {
    position: fixed;
    left: 0;
    width: 100%;

    .lines {
      margin-top: 16px;
      border-bottom: 1px solid #000;

      .home & {
        border: 0;
      }
    }
  }

}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #000;
  z-index: 3;

  .home & {
    color: $white;
  }

  #copyright {
    height: 20px;
    background: #000;
    text-transform: uppercase;
    text-align: right;
    padding-right: 38px;
    padding-top: 2px;

    @media #{$small-only} {
      text-align: center;
      padding: 0;
    }

    p {
      font-family: "MyriadPro-Cond";
      font-size: 11px;
      color: #929292;
    }
  }
}

// Placed here because @media is not working in carousel.scss??
.owl-carousel .owl-item {
  img {
    @media #{$small-only} {
      height: auto !important;
    }
  }
}

