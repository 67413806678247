$color-base:			#000;
$color-white:			#FFF;
$color-gray:			#bcbcbc;

//nav

$nav-color: 			$color-white;
$nav-color-hover:		$color-white;
$nav-font-size: 		14px;
$nav-rounded: 			3px;
$nav-margin: 			5px;
$nav-padding: 			4px 7px;
$nav-background: 		$color-gray;
$nav-background-hover:	$color-base;
$nav-disabled-opacity: 	0.5;

//dots

$dot-width:				10px;
$dot-height:			10px;
$dot-rounded:			30px;
$dot-margin: 			5px 10px;
$dot-background:		$color-gray;
$dot-background-active:	$color-base;

@import '../../bower_components/owl.carousel/src/scss/mixins';
@import '../../bower_components/owl.carousel/src/scss/theme';

.owl-carousel .owl-item {
  img {
    height: 415px;
    width: auto !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.owl-carousel .item {

  display: inline-block;
  margin:0px;
  width: 100%;
}
