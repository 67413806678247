
.owl-theme {
	// controls container
	.owl-controls {
		margin-top: 10px;
		text-align: center;
		-webkit-tap-highlight-color: transparent;
	}

	// Styling Next and Prev buttons
	.owl-controls {
		.owl-nav {
			[class*='owl-'] {
			color: $nav-color;
			font-size: $nav-font-size;
			margin: $nav-margin;
			padding: $nav-padding;
			background: $nav-background;
			display: inline-block;
			cursor: pointer;
			@include rounded($nav-rounded);

			&:hover {
				background: $nav-background-hover;
				color:$nav-color-hover;
				text-decoration: none;
				}
			}
			.disabled {
				opacity: $nav-disabled-opacity;
				cursor: default;
			}
		}
	}

	// Styling dots
	.owl-dots {
		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: $dot-width;
				height: $dot-height;
				margin: $dot-margin;
				background: $dot-background;
				display: block;
				-webkit-backface-visibility: visible;
				@include transition(opacity, 200ms, ease);
				@include rounded($dot-rounded);
			}

			&.active,
			&:hover {
				span {
					background: $dot-background-active;
				}
			}
		}
	}
}