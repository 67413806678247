@import "../../../bower_components/foundation/scss/foundation/functions";

$font-family-sans-serif: "MyriadPro", Helvetica, Roboto, Arial, sans-serif;

$input-bg-color: #dddddd;
$input-border-style: none;
$input-box-shadow: none;

// Forms
$form-label-font-size: rem-calc(16);
$form-label-line-height: 2;